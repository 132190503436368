<template>
  <b-modal id="change-password" hide-footer centered>
    <template #modal-header="{ close }">
      <b-button size="sm" variant="outline-danger" @click="close()"></b-button>
    </template>
    <b-container>
      <b-row>
        <b-col cols="12" class="px-5">
          <form @submit.prevent="sendForm" class="pb-4">

            <h3 class="text-center">Change Password</h3>

            <!-- Old Password -->
            <b-form-group class="mb-4">
              <b-form-input type="password" placeholder="Previous password"
                :class="{'is-invalid': $v.form.password_previous.$error}" v-model.trim="$v.form.password_previous.$model"></b-form-input>

              <span role="alert" class="invalid-feedback" v-if="$v.form.password_previous.$error">
                <span v-if="!$v.form.password_previous.required">The previous password is required</span>
                <span v-if="!$v.form.password_previous.minLength">
                  The previous password must have at least {{$v.form.password_previous.$params.minLength.min}} characters
                </span>
                <span v-if="!$v.form.password_previous.maxLength">
                  The previous password must be less than {{$v.form.password_previous.$params.maxLength.max}} characters
                </span>
              </span>
            </b-form-group>

            <!-- New Password -->
            <b-form-group class="mb-4">
              <b-form-input type="password" placeholder="New password" trim
                :class="{'is-invalid': $v.form.password.$error || (!checkPassword.success && form.password != '' && form.password != null)}"
                v-model.trim="$v.form.password.$model"></b-form-input>
              <span role="alert" class="invalid-feedback" v-if="$v.form.password.$error">
                <span v-if="!$v.form.password.required">The new password is required</span>
                <span v-if="!$v.form.password.maxLength">
                  The new password must be less than {{$v.form.password.$params.maxLength.max}} characters
                </span>
              </span>
              <span class="validation_password mt-1" v-if="form.password != '' && form.password != null">
                <span class="validation_password--text" :class="[checkPassword.level]">
                  {{checkPassword.title}}
                </span>
                <span class="validation_password--description">
                  {{checkPassword.description}}
                </span>
              </span>
            </b-form-group>

            <!-- Confirm Password -->
            <b-form-group class="mb-4">
              <b-form-input type="password" placeholder="Confirm password" trim
                :class="{'is-invalid': $v.form.password_confirmation.$error}" v-model.trim="$v.form.password_confirmation.$model"></b-form-input>
              <span role="alert" class="invalid-feedback" v-if="$v.form.password_confirmation.$error">
                <span v-if="!$v.form.password_confirmation.required">The confirm password is required.</span>
                <span v-if="!$v.form.password_confirmation.sameAsPassword && $v.form.password_confirmation.required">The confirm password confirmation does not match.</span>
              </span>
            </b-form-group>

            <b-button type="submit" variant="primary" class="w-100">Save</b-button>
          </form>
        </b-col>
      </b-row>
    </b-container>
    <b-overlay :show="load" rounded="sm" no-wrap></b-overlay>
  </b-modal>
</template>

<script>
import {required, sameAs, minLength, maxLength} from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      form: {
        password_previous: String(),
        password: String(),
        password_confirmation: String(),
      },
      load: false,
    }
  },
  validations: {
    form: {
      password_previous: { required, minLength: minLength(8), maxLength: maxLength(30) },
      password: { 
        required, minLength: minLength(8), maxLength: maxLength(30),
        isValid(value){
          return this.checkLevelPassword(value).success;
        }
      },
      password_confirmation: { required, sameAsPassword: sameAs('password') },
    },
  },
  methods: {
    sendForm() {
      this.$v.form.$reset();
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.load = true;
      }
    },
  },
  computed: {
    checkPassword(){
      return this.checkLevelPassword(this.form.password);
    }
  },
};
</script>
