<template>
  <div class="account-information">
    <Navbar />
    <b-container class="container_account-information">
      <form @submit.prevent="sendForm">
        <b-row>
          <h3 class="mb-4">Edit Profile</h3>
          <b-col cols="12" lg="6" class="pe-lg-5">

            <!-- Name -->
            <b-form-group label="Name" label-for="name" class="mb-3" :class="{'error-form-group': $v.form.name.$error}">
              <b-form-input id="name" type="text" placeholder="John"
                :class="{'is-invalid': $v.form.name.$error}" v-model.trim="$v.form.name.$model"></b-form-input>

              <span role="alert" class="invalid-feedback" v-if="$v.form.name.$error">
                <span v-if="!$v.form.name.required">The name is required</span>
                <span v-if="!$v.form.name.minLength">
                  The name must have at least {{$v.form.name.$params.minLength.min}} characters
                </span>
                <span v-if="!$v.form.name.maxLength">
                  The name must be less than {{$v.form.name.$params.maxLength.max}} characters
                </span>
              </span>
            </b-form-group>

            <!-- Last Name -->
            <b-form-group label="Last Name" label-for="last_name" class="mb-3" :class="{'error-form-group': $v.form.name.$error}">
              <b-form-input id="last_name" type="text" placeholder="Doe"
                :class="{'is-invalid': $v.form.last_name.$error}" v-model.trim="$v.form.last_name.$model"></b-form-input>

              <span role="alert" class="invalid-feedback" v-if="$v.form.last_name.$error">
                <span v-if="!$v.form.last_name.required">The last name is required</span>
                <span v-if="!$v.form.last_name.minLength">
                  The last name must have at least {{$v.form.last_name.$params.minLength.min}} characters
                </span>
                <span v-if="!$v.form.name.maxLength">
                  The last name must be less than {{$v.form.last_name.$params.maxLength.max}} characters
                </span>
              </span>
            </b-form-group>

            <!-- Email -->
            <b-form-group label="Email" label-for="email" class="mb-3" :class="{'error-form-group': $v.form.email.$error}">
              <b-form-input id="email" type="email" placeholder="user@user.com"
                :class="{'is-invalid': $v.form.email.$error}" v-model.trim="$v.form.email.$model"></b-form-input>

              <span role="alert" class="invalid-feedback">
                <span v-if="!$v.form.email.required">The email is required</span>
                <span v-if="!$v.form.email.email">Invalid email format</span>
                <span v-if="!$v.form.email.maxLength">
                  The email must be less than {{$v.form.email.$params.maxLength.max}} letters
                </span>
              </span>
            </b-form-group>

            <!-- Phone -->
            <b-form-group label="Phone" label-for="phone" class="mb-3 mb-lg-5">
              <b-form-input id="phone" type="text" placeholder="+57 123 456 78 90"></b-form-input>
            </b-form-group>

            <b-button type="submit" class="w-100 d-none d-lg-block py-3" @click="$bvModal.show('change-password')">Change Password </b-button>
          
          </b-col>
          <b-col cols="12" lg="6" class="ps-lg-5">
            <!-- Language -->
            <b-form-group label="Language" label-for="language" class="mb-3" :class="{'error-form-group': $v.form.language.$error}">
              <b-form-select id="language" v-model="$v.form.language.$model" :options="language_options"
                :class="{'is-invalid': $v.form.language.$error}" class="form-control"></b-form-select>

              <span role="alert" class="invalid-feedback" v-if="$v.form.language.$error">
                <span v-if="!$v.form.language.required">
                  The language is required
                </span>
              </span>
            </b-form-group>

            <!-- Country -->
            <b-form-group label="Country" label-for="country" class="mb-3 mb-lg-5" :class="{'error-form-group': $v.form.country.$error}">
              <b-form-select id="country" v-model="$v.form.country.$model" :options="country_options"
                :class="{'is-invalid': $v.form.country.$error}" class="form-control"></b-form-select>

              <span role="alert" class="invalid-feedback" v-if="$v.form.country.$error">
                <span v-if="!$v.form.country.required">
                  The country is required
                </span>
              </span>
            </b-form-group>

            <!-- Notifications -->
            <b-form-group label="Notifications" class="mb-3">
              <!-- SMS -->
              <b-card no-body class="py-4 px-5 mb-4">
                <b-row align-h="center" align-v="center">
                  <b-col cols="2">
                    <b-img :src="require('@/assets/img/sms.png')" width="40"></b-img>
                  </b-col>
                  <b-col>
                    <p class="mb-0 title">Text message (SMS)</p>
                    <p class="mb-0 text">Turn on text message notifications</p>
                    <b-badge>Authenticate</b-badge>
                  </b-col>
                  <b-col cols="2">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch">
                    </div>
                  </b-col>
                </b-row>
              </b-card>

              <!-- WhatsApp -->
              <b-card no-body class="py-4 px-5">
                <b-row align-h="center" align-v="center">
                  <b-col cols="2">
                    <b-img :src="require('@/assets/img/wpp.png')" width="40"></b-img>
                  </b-col>
                  <b-col>
                    <p class="mb-0 title">WhatsApp message</p>
                    <p class="mb-0 text">Activate whatsapp notifications</p>
                    <b-badge>Authenticate</b-badge>
                  </b-col>
                  <b-col cols="2">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" role="switch">
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-form-group>
          </b-col>
          <!-- Recaptcha -->
          <vue-recaptcha ref="recaptcha" sitekey="6LfZ-wYfAAAAAP7rkAPUU3RKiJixfyVmN4Ytz0TC" 
            size="invisible" @verify="validateRecaptcha" @expired="validateRecaptcha">
          </vue-recaptcha>
        </b-row>
      </form>
    </b-container>
    <b-overlay :show="load" rounded="sm" no-wrap></b-overlay>
    <Footer />
    <ChangePassword />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import ChangePassword from '@/views/Account/ChangePassword.vue';
import {required, email, minLength, maxLength} from 'vuelidate/lib/validators';
import VueRecaptcha from 'vue-recaptcha';

export default {
  components: {
    Navbar,
    Footer,
    VueRecaptcha,
    ChangePassword
  },
  data() {
    return {
      form: {
        name: String(),
        last_name: String(),
        email: String(),
        phone: String(),
        language: null,
        country: null,
        recaptcha: null,
      },
      language_options: [
        { value: null, text: 'Language' },
        { value: 'Language #1', text: 'Language #1' }
      ],
      country_options: [
        { value: null, text: 'Country' },
        { value: 'Country #1', text: 'Country #1' }
      ],
      load: false,
    }
  },
  validations: {
    form: {
      name: { required, minLength: minLength(2), maxLength: maxLength(60) },
      last_name: { required, minLength: minLength(2), maxLength: maxLength(60) },
      email: { required, email, maxLength: maxLength(60) },
      language: { required },
      country: { required },
    },
  },
  methods: {
    sendForm() {
      this.$v.form.$reset();
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.load = true;
        this.generateRecaptcha();
      }
    },
    validateRecaptcha(response){
      this.$refs.recaptcha.reset();
      this.form.recaptcha = response;
    },
    generateRecaptcha(){
      this.$refs.recaptcha.execute();
    },
  }
};
</script>
